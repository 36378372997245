// @flow
import { SERIES_COLORS } from 'components/QueryResult/graphUtil';

export const DEFAULT_BUBBLE_COLOR: string = SERIES_COLORS[0];

export const LABEL_LAYER_ID = 'data-point-label-layer';

export const GEO_FIELD_ORDERING: Array<string> = (
  window.__JSON_FROM_BACKEND.geoFieldOrdering || []
).slice();

// HACK(stephen): Huge hack for mozambique. Need a way to include a metadata
// dimension as a mappable dimension.
// $CycloneIdaiHack
if (window.__JSON_FROM_BACKEND.deploymentName === 'mz') {
  GEO_FIELD_ORDERING.push('BairroName');
}

// HACK(stephen): Giant, awful, sad hack. We desperately need a better way of
// marking "mappable" dimensions. PK can map this dimension when the user
// selects the colored tiles option since it is part of the geojson.
if (window.__JSON_FROM_BACKEND.deploymentName === 'pk') {
  // HACK(stephen): THIS IS SO DIRTY. To support this dimension for an upcoming
  // demo, we modify the backend config along with the map config. To avoid this
  // dirty modification of the backend config causing unknown errors on other
  // parts of the site, we limit it to just AQT and dashboards. Also PK doesn't
  // use translations so we're safe checking it this way.
  const { pathname } = window.location;
  if (
    pathname.startsWith('/advanced-query') ||
    pathname.startsWith('/dashboard')
  ) {
    // HACK(stephen): A new hack! This is just terrible! The MapControlsBlock
    // for some reason ... takes all geo dimensions except the last one when
    // building the admin boundary list. So ... we make it seem like our
    // special dimension here is not the last one ... because we freaking want
    // it to show up in the dropdown. THIS HURTS SO BADLY. Also, because someone
    // might have already taken a reference to the backend config value, we must
    // modify it in-place. OH WAIT IT DOESN'T EVEN WORK because the
    // BackgroundShapeLayer has its own assumptions about geo ordering for
    // reasons I don't remember anymore! But we still don't want the Facility
    // dimension to show up in the background shape list so here we are still.
    const insertHackDimensionInPlace = list => {
      const lastItem = list.pop();
      list.push('EPIVaccinatorName');
      list.push(lastItem);
    };
    insertHackDimensionInPlace(GEO_FIELD_ORDERING);
    insertHackDimensionInPlace(window.__JSON_FROM_BACKEND.geoFieldOrdering);
  }
}
