// @flow
import * as React from 'react';

import SegmentOptionTitle from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionTitle';

type Props = {
  className?: string,
  option: React.Node,
  prefix?: React.Node | void,
  title: React.Element<typeof SegmentOptionTitle> | string,
};

export default function SegmentOptionBlock({
  option,
  title,
  className = '',
  prefix = undefined,
}: Props): React.Node {
  const wrappedTitle =
    typeof title === 'string' ? (
      <SegmentOptionTitle>{title}</SegmentOptionTitle>
    ) : (
      title
    );
  return (
    <div className={`segment-option-block ${className}`}>
      {prefix && <div className="segment-option-block__prefix">{prefix}</div>}
      <div className="segment-option-block__title">{wrappedTitle}</div>
      <div className="segment-option-block__option">{option}</div>
    </div>
  );
}
