// @flow
import * as React from 'react';

import I18N from 'lib/I18N';
import Tooltip from 'components/ui/Tooltip';
import { SET_OPERATION_LABEL } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import type { SetOperation } from 'models/core/wip/Calculation/CohortCalculation/types';

type Props = {
  cohortGroupIdx: number,
  cohortGroupOperation: SetOperation,
  cohortIdx: number,
  onCohortGroupOperationToggle?: (
    ungroup: boolean,
    cohortIdx: number,
    cohortGroupOperation: number,
  ) => void,
};

function CohortGroupOperationRow({
  cohortGroupIdx,
  cohortGroupOperation,
  cohortIdx,
  onCohortGroupOperationToggle = undefined,
}: Props): React.Node {
  const extraClassName =
    cohortGroupOperation === 'UNION'
      ? 'cohort-group-operation-row--union'
      : 'cohort-group-operation-row--intersect';
  const willUngroup = cohortGroupOperation === 'INTERSECT';
  const onToggle =
    onCohortGroupOperationToggle !== undefined
      ? () =>
          onCohortGroupOperationToggle(willUngroup, cohortIdx, cohortGroupIdx)
      : onCohortGroupOperationToggle;
  return (
    <div className={`cohort-group-operation-row ${extraClassName}`}>
      <Tooltip
        content={
          willUngroup
            ? I18N.text('Create group (AND)')
            : I18N.text('Ungroup (OR)')
        }
        popoverClassName="cohort-group-operation-row__tooltip"
      >
        <div
          className="cohort-group-operation-row__tag"
          onClick={onToggle}
          role="button"
        >
          {SET_OPERATION_LABEL[cohortGroupOperation]}
        </div>
      </Tooltip>
    </div>
  );
}

export default (React.memo(
  CohortGroupOperationRow,
): React.AbstractComponent<Props>);
