// @flow
import * as React from 'react';
import numeral from 'numeral';

import LoadingSpinner from 'components/ui/LoadingSpinner';
import type { StyleObject } from 'types/jsCore';

type Props = {
  className?: string | void,
  fontSize: number,
  style?: StyleObject | void,
  value: number | void,
};

export default function Number({
  fontSize,
  value,
  className = undefined,
  style = undefined,
}: Props): React.Node {
  if (value === undefined) {
    return (
      <LoadingSpinner className={className} size={fontSize} style={style} />
    );
  }

  return (
    <div className={className} style={{ ...style, fontSize }}>
      {numeral(value).format('0,0')}
    </div>
  );
}
