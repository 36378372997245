// @flow
import * as Zen from 'lib/Zen';
import CalculationUtil from 'models/core/wip/Calculation/CalculationUtil';
import Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import CohortCalculation from 'models/core/wip/Calculation/CohortCalculation';
import CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import CountDistinctCalculation from 'models/core/wip/Calculation/CountDistinctCalculation';
import type Dimension from 'models/core/wip/Dimension';
import type {
  Calculation,
  CalculationType,
} from 'models/core/wip/Calculation/types';
import type { QueryFilter } from 'models/core/wip/QueryFilter/types';

/**
 * @param {Calculation} calculation The calculation we are casting
 * @param {CalculationType} calculationType The type we are casting to
 * @param {Dimension} defaultDimension The default dimension to use for a
 * calculation if one is needed.
 * @param {string} fieldName The field name
 * @param {QueryFilter | null} newFilter The filter to use
 */
export default function convertCalculationToNewType(
  calculation: Calculation,
  calculationType: CalculationType,
  defaultDimension: Dimension,
  fieldName: string,
  newFilter: QueryFilter | null,
): Calculation {
  const filter = newFilter || calculation.get('filter');
  if (calculationType === 'COUNT_DISTINCT') {
    return CountDistinctCalculation.create({
      filter,
      dimension: defaultDimension.id(),
    });
  }

  if (calculationType === 'COHORT') {
    // Quality of life improvement: preserve the dimension being operated on
    // if casting from a COUNT DISTINCT calculation to a COHORT calculation.
    const dimensionId =
      calculation.tag === 'COUNT_DISTINCT'
        ? calculation.dimension()
        : defaultDimension.id();

    // NOTE(stephen): It is kind of inconvenient to have to pass the field
    // name into the calculation customization block just so that cohort
    // segments are created cleanly.
    const initialCohortGroup = CohortGroup.create({})
      .deepUpdate()
      .primarySegment()
      .field({
        filter,
        name: fieldName,
      });

    return CohortCalculation.create({
      cohorts: Zen.Array.create([
        Cohort.create({
          cohortGroups: Zen.Array.create([initialCohortGroup]),
        }),
      ]),
      dimension: dimensionId,
      // NOTE(stephen): We have to drop the original filter since it is now
      // being applied to an inner cohort segmenet. If we apply the filter to
      // the full calculation then the full calculation will be wrong.
      filter: null,
    });
  }

  if (filter !== null) {
    return CalculationUtil.castCalculation(
      calculationType,
      calculation,
      filter,
    );
  }
  return CalculationUtil.castCalculation(calculationType, calculation);
}
