// @flow
import Promise from 'bluebird';
import invariant from 'invariant';

import * as Zen from 'lib/Zen';
// TODO(pablo): fix this cyclical dependency
// eslint-disable-next-line import/no-cycle
import CaseManagementService from 'services/CaseManagementService';
import DimensionService from 'services/wip/DimensionService';
import DimensionValueFilterItem from 'models/core/wip/QueryFilterItem/DimensionValueFilterItem';
import ExternalAlert from 'models/CaseManagementApp/ExternalAlert';
import FieldService from 'services/wip/FieldService';
import GroupingDimension from 'models/core/wip/GroupingItem/GroupingDimension';
import Moment from 'models/core/wip/DateTime/Moment';
import QuerySelections from 'models/core/wip/QuerySelections';
import TableQueryEngine from 'models/visualizations/Table/TableQueryEngine';
import { FullDimensionValueService } from 'services/wip/DimensionValueService';
import type AlertCaseCoreInfo from 'models/CaseManagementApp/AlertCaseCoreInfo';
import type AlertCaseType, {
  ExternalAlertType,
} from 'models/CaseManagementApp/AlertCaseType';
import type AlertDefinition from 'models/AlertsApp/AlertDefinition';
import type AlertNotification from 'models/AlertsApp/AlertNotification';
import type Dimension from 'models/core/wip/Dimension';
import type DimensionValue from 'models/core/wip/Dimension/DimensionValue';
import type Field from 'models/core/wip/Field';

/**
 * Check if this AlertNotification matches an external alert we pulled from
 * an external system. Two alerts are considered matching if they have the
 * same date period for the alert, AND if their dimensionName
 * (e.g. 'FacilityName'), dimensionValue (e.g. 'CS Munhava') and fieldId
 * (e.g. 'ewars_sarampo') are all equal.
 */
export function doAlertsMatch(
  alert: AlertNotification,
  alertDefinition: AlertDefinition,
  externalAlert: ExternalAlert,
): boolean {
  // NOTE(abby): We store dates as inclusive/ exclusive.
  const {
    dimensionId,
    dimensionValue,
    queryEndDate,
    queryStartDate,
  } = alert.modelValues();
  // External alerts matching is done only for threshold style alerts
  if (alertDefinition.checks().some(check => check.tag !== 'THRESHOLD')) {
    return false;
  }
  // Since it's only threshold alerts, just need the first field
  const fieldId = alertDefinition
    .fields()
    .first()
    .originalId();

  // firstly, check the easy fields
  if (
    fieldId !== externalAlert.fieldId() ||
    dimensionId !== externalAlert.dimensionName() ||
    dimensionValue !== externalAlert.dimensionValue()
  ) {
    return false;
  }

  // then check if the alert dates match
  const { alertEndDate, alertStartDate } = externalAlert.modelValues();
  const isZenAlertSingleDay = alert.isSingleDatePeriod();

  // This should cover both systems like EWARS that represents
  // single-day alerts with the *same* day as start and end date) and
  // other alert systems that represent single-day alerts like we do
  // (with a date period of [X, X+1]).
  const extStartDate = Moment.create(alertStartDate);
  const extEndDate = Moment.create(alertEndDate);
  const isExtAlertSingleDay = extStartDate.diff(extEndDate, 'days') <= 1;

  // return false if the alerts are for different date types
  if (isZenAlertSingleDay !== isExtAlertSingleDay) {
    return false;
  }

  // single day alerts, but they aren't for the same day
  if (
    isZenAlertSingleDay &&
    isExtAlertSingleDay &&
    !queryStartDate.isSame(extStartDate) // we don't start on the same day
  ) {
    return false;
  }

  // finally: date-ranged alerts, but the periods don't line up
  return (
    isZenAlertSingleDay ||
    isExtAlertSingleDay ||
    (queryStartDate.isSame(extStartDate) && queryEndDate.isSame(extEndDate))
  );
}

/**
 * This class is used internally by CaseManagementService to load any external
 * alerts that need to be integrated into the CaseManagementApp. For example,
 * in MZ we need to merge alerts raised by EWARS into our own Zenysis alerts.
 *
 * BACKGROUND INFO:
 *   One of the biggest strengths of the Case Management App is the ability to
 *   merge alerts from different sources (in the same way that our entire
 *   platform is based on the ability to merge data from multiple different
 *   sources).
 *
 *   In our CaseManagementService, in order to load all alert cases, we fetch
 *   all alerts from our AlertsService. But then we *also* use this
 *   ExternalAlertService to fetch other alerts that aren't stored in postgres,
 *   because sometimes our clients use other alerting systems and those alerts
 *   have important metadata that need to show up in this app.
 *
 *   These alerts are stored in druid, and they are fetched externally during
 *   a pipeline step. For example, in MZ, we do this in `pipeline/mozambique/
 *   bin/ewars/fetch_alert_data.py`. All external alerts are dumped into
 *   some indicator id (see config: `externalAlertFieldId`), and the alert's
 *   is dumped in a dimension (see config: `externalAlertDataDimension`).
 *   By querying for these two things (as well as the dimension we need to
 *   aggregate by, e.g. FacilityName), we can retrieve all external alerts.
 *
 *   After that, we need to match external alerts with our internal postgres
 *   alerts in order to merge their data. That way our Case Management App
 *   can show a unified view of a single alert, even though an alert's
 *   metadata is coming from different sources.
 */
class ExternalAlertsService {
  // TODO(abby): test with no external alert types and multiple external
  //  alert types Should work, but I may have missed something converting
  //  external alert type from one type to an array
  /**
   * Helper function to fetch external alerts available in druid. We do this
   * by running an AQT Table Query, and querying for `externalAlertFieldId`,
   * grouped by `externalAlertDataDimension` (which holds all the external alert
   * JSON), and by `externalAlertDimension` (e.g. 'FacilityName'). Optionally,
   * can also filter by an alert case to return only the external alerts for the
   * same dimension.
   */
  getExternalAlerts(
    alertCaseType: AlertCaseType,
    alertCaseCoreInfo?: AlertCaseCoreInfo,
  ): Promise<Array<ExternalAlert>> {
    // There can be multiple types of external alerts
    return Promise.all([
      Promise.all(
        alertCaseType
          .externalAlertTypes()
          .values()
          .filter(
            externalAlertType =>
              !alertCaseCoreInfo ||
              externalAlertType.druidDimension ===
                alertCaseCoreInfo.alertNotification().dimensionId(),
          )
          .map((externalAlertType: ExternalAlertType) => {
            const {
              dataDimension,
              druidDimension,
              fieldId,
            } = externalAlertType;

            const promises = [
              FieldService.get(fieldId),
              DimensionService.get(dataDimension),
              DimensionService.get(druidDimension),
            ];

            if (alertCaseCoreInfo) {
              const {
                dimensionValue,
              } = alertCaseCoreInfo.alertNotification().modelValues();
              promises.push(
                FullDimensionValueService.getAll().then(vals =>
                  vals.find(v => v.name() === dimensionValue),
                ),
              );
            }
            return Promise.all(promises);
          }),
      ),
      CaseManagementService.getCaseTypes(),
    ]).then(result => {
      const [externalAlertTypes, configMap] = result;
      const alertCaseTypeEntry = Zen.cast<AlertCaseType>(
        configMap
          .values()
          .find(caseType => caseType.get('caseType') === 'ALERT'),
      );

      return (
        Promise.all(
          externalAlertTypes.map(externalAlertType => {
            // NOTE(stephen): Unpacking result directly instead of inline to
            // allow us to specify flow types.
            const externalAlertField: Field = externalAlertType[0];
            const dataDimension: Dimension = externalAlertType[1];
            const alertDimension: Dimension = externalAlertType[2];
            const druidDimension = alertDimension.id();

            const queryFilter = [];
            if (alertCaseCoreInfo) {
              const dimensionValue: DimensionValue = externalAlertType[3];
              const dimensionValueFilter = DimensionValueFilterItem.createFromDimensionValues(
                dimensionValue,
              );

              queryFilter.push(dimensionValueFilter);
            }

            const querySelections = QuerySelections.create({
              fields: Zen.Array.create([externalAlertField]),
              filter: Zen.Array.create(queryFilter),
              groups: Zen.Array.create([
                GroupingDimension.createFromDimension(dataDimension.id()),
                GroupingDimension.createFromDimension(alertDimension.id()),
              ]),
            });
            return TableQueryEngine.run(querySelections).then(tableResults =>
              // return an array of alert data
              tableResults.data.map(dataObj => {
                const externalAlert = dataObj[dataDimension.id()];
                const dimensionValue = dataObj[druidDimension];
                invariant(
                  externalAlert !== null && typeof externalAlert !== 'number',
                  'External alert that we load from the backend must be a string',
                );
                invariant(
                  dimensionValue !== null && typeof dimensionValue !== 'number',
                  'External alerts can only have dimension values that are strings.',
                );

                return ExternalAlert.deserialize(JSON.parse(externalAlert), {
                  dimensionValue,
                  alertCaseType: alertCaseTypeEntry,
                  dimensionName: druidDimension,
                });
              }),
            );
          }),
        )
          // flatten the nested arrays into one array since we don't need them
          // separated by external alert type
          .then(
            nestedArray =>
              (nestedArray.reduce(
                (arr, x) => arr.concat(x),
                [],
              ): Array<ExternalAlert>),
          )
      );
    });
  }

  /**
   * Fetch all external alerts available in druid.
   */
  getAllExternalAlerts(): Promise<Array<ExternalAlert>> {
    return CaseManagementService.getCaseTypes().then(configMap => {
      const alertCaseType = Zen.cast<AlertCaseType>(
        configMap
          .values()
          .find(caseType => caseType.get('caseType') === 'ALERT'),
      );
      return this.getExternalAlerts(alertCaseType);
    });
  }

  /**
   * For a given AlertCaseCoreInfo, find a matching external alert to merge with
   * it. If there is no matching alert, return undefined.
   */
  getMatchingExternalAlert(
    alertCaseCoreInfo: AlertCaseCoreInfo,
    alertDefinition: AlertDefinition,
  ): Promise<ExternalAlert | void> {
    return this.getExternalAlerts(
      alertCaseCoreInfo.type(),
      alertCaseCoreInfo,
    ).then((externalAlerts: Array<ExternalAlert>) =>
      externalAlerts.find(extAlertData =>
        doAlertsMatch(
          alertCaseCoreInfo.alertNotification(),
          alertDefinition,
          extAlertData,
        ),
      ),
    );
  }
}

export default (new ExternalAlertsService(): ExternalAlertsService);
